<template>
  <div>
    <v-app-bar app>
      <v-app-bar-title>CM Comms Center</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout()" text>Logout</v-btn>
    </v-app-bar>
    <v-main>
      <v-col>
        <v-card>
          <v-card-title>Add User</v-card-title>
          <v-col>
            <v-form>
              <v-text-field
                v-model="firstName"
                label="First Name"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                label="Last Name"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userNumber"
                label="Phone Number"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userEmail"
                label="Email"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userCompany"
                label="Company"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userPassword"
                label="Password"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userLogo"
                label="Company Logo URL"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userColour"
                label="Company Colour (HEX)"
                outlined
              ></v-text-field>
              <v-btn @click="addUser()">Add</v-btn>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      firstName: null,
      lastName: null,
      userNumber: null,
      userEmail: null,
      userCompany: null
    };
  },
  methods: {
    addUser() {
      axios
        .post(
          "https://winsoc-caller-backend.herokuapp.com/api/user",
          [
            {
              email: this.userEmail,
              first_name: this.firstName,
              last_name: this.lastName,
              number: this.userNumber,
              company: this.userCompany,
              password: this.userPassword,
              white_label_logo: this.userLogo,
              white_label_colour: this.userColour
            },
          ],
          {
            headers: {
              "Content-Type": "application/json",
              user_id: localStorage.getItem("ID"),
              token: localStorage.getItem("Token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout() {
      localStorage.removeItem("Token");
      localStorage.removeItem("ID");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
</style>
