import Vue from 'vue'
import VueRouter from 'vue-router'

//Views
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Calls from '../views/Calls.vue'
import Messages from '../views/Messages.vue'
import Conversation from '../views/Conversation.vue'
import Edit from '../views/Edit.vue'
import Add from '../views/Add.vue'
import Companies from '../views/Companies.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Companies,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Users',
    name: 'Users',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Add',
    name: 'Add',
    component: Add,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Edit',
    name: 'Edit',
    component: Edit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Calls',
    name: 'Calls',
    component: Calls,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Messages',
    name: 'Messages',
    component: Messages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Conversation',
    name: 'Conversation',
    component: Conversation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

var isAuthenticated = localStorage.getItem('Token')

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && isAuthenticated == undefined) next({ name: 'Login' })
  else next()
})

export default router
