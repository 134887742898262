<template>
  <div>
    <v-app-bar app>
      <v-app-bar-title>CM Comms Center</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout()" text>Logout</v-btn>
    </v-app-bar>
    <v-main>
      <v-col>
        <v-card>
          <v-card-title>Edit User</v-card-title>
          <v-col>
            <v-form>
              <v-text-field
                v-model="firstName"
                label="First Name"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                label="Last Name"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userNumber"
                label="Phone Number"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="userEmail"
                label="Email"
                outlined
              ></v-text-field>
              <v-btn @click="editUser()">Edit</v-btn>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      firstName: null,
      lastName: null,
      userNumber: null,
      userEmail: null,
    };
  },
  created() {
    axios
      .post(
        "https://winsoc-caller-backend.herokuapp.com/api/admin/user",
        {
          userId: this.$route.query.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            user_id: localStorage.getItem("ID"),
            token: localStorage.getItem("Token"),
          },
        }
      )
      .then((res) => {
        this.firstName = res.data.data.first_name;
        this.lastName = res.data.data.last_name;
        this.userEmail = res.data.data.email;
        this.userNumber = res.data.data.number;
      });
  },
  methods: {
    editUser() {
      axios
        .post(
          "https://winsoc-caller-backend.herokuapp.com/api/admin/edit",
          {
            userId: this.$route.query.userId,
            userEmail: this.userEmail,
            firstName: this.firstName,
            lastName: this.lastName,
            userNumber: this.userNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              user_id: localStorage.getItem("ID"),
              token: localStorage.getItem("Token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout() {
      localStorage.removeItem("Token");
      localStorage.removeItem("ID");
      this.$router.push("/login");
    },
    addUser() {
      var url = "/Add";
      this.$router.push(url);
    },
  },
};
</script>

<style>
</style>
