<template>
  <div>
    <v-app-bar app>
      <v-app-bar-title>CM Comms Center</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="addUser()" text>Add User</v-btn>
      <v-btn @click="logout()" text>Logout</v-btn>
    </v-app-bar>
    <v-main>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Calls</th>
              <th class="text-left">Messages</th>
              <th class="text-left">Edit</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userData" :key="item.id">
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>
                <v-btn @click="openCalls(item.id)" text>{{
                  item.calls
                }}</v-btn>
              </td>
              <td>
                <v-btn @click="openMessages(item.id)" text>{{
                  item.messages
                }}</v-btn>
              </td>
              <td>
                <v-btn @click="editUser(item.id)" text>Edit User</v-btn>
              </td>
              <td>
                <v-btn @click="deleteUser(item.id)" text color="error"
                  >Delete User</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userData: null,
    };
  },
  created() {
    axios
      .post("https://winsoc-caller-backend.herokuapp.com/api/admin/users",{company: this.$route.query.company} ,{
        headers: {
          user_id: localStorage.getItem("ID"),
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        this.userData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    openCalls(userId) {
      var url = "/Calls?userId=" + userId;
      this.$router.push(url);
    },
    openMessages(userId) {
      var url = "/Messages?userId=" + userId;
      this.$router.push(url);
    },
    editUser(userId) {
      var url = "/Edit?userId=" + userId;
      this.$router.push(url);
    },
    deleteUser(userId) {
      axios.post(
        "https://winsoc-caller-backend.herokuapp.com/api/admin/delete",
        {
          userId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            user_id: localStorage.getItem("ID"),
            token: localStorage.getItem("Token"),
          },
        }
      );
    },
    addUser() {
      var url = "/Add";
      this.$router.push(url);
    },
    logout() {
      localStorage.removeItem("Token");
      localStorage.removeItem("ID");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
</style>
