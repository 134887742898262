<template>
  <div>
    <v-app-bar app>
      <v-app-bar-title>CM Comms Center</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="addUser()" text>Add User</v-btn>
      <v-btn @click="logout()" text>Logout</v-btn>
    </v-app-bar>
    <v-main>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">To</th>
              <th class="text-left">Timestamp</th>
              <th class="text-left">Recording</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userData" :key="item.id">
              <td>
                {{ item.outbound_number }}
              </td>
              <td>
                {{ item.createdAt }}
              </td>
              <td>
                <v-btn @click="openRecording(item.recording)" text
                  >Recording</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userData: null,
    };
  },
  created() {
    axios
      .post(
        "https://winsoc-caller-backend.herokuapp.com/api/logs",
        {
          user: this.$route.query.userId,
        },
        {
          headers: {
            token: localStorage.getItem("Token"),
          },
        }
      )
      .then((res) => {
        this.userData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    openRecording(value) {
      axios
        .post(
          "https://winsoc-caller-backend.herokuapp.com/api/recording",
          {
            sid: value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("Token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          window.open(
            "https://api.twilio.com/2010-04-01/Accounts/AC2bba5892c694f3ad954956db37b7fec5/Recordings/" +
              res.data.sid +
              ".mp3",
            "_blank"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout() {
      localStorage.removeItem("Token");
      localStorage.removeItem("ID");
      this.$router.push("/login");
    },
    addUser() {
      var url = "/Add";
      this.$router.push(url);
    },
  },
};
</script>

<style>
</style>
