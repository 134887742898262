<template>
  <div>
    <v-main>
      <center>
        <v-col cols="6">
          <v-card>
            <v-card-title>Login</v-card-title>
            <v-col>
              <v-form>
                <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  outlined
                ></v-text-field>
                <v-btn @click="login()">Login</v-btn>
              </v-form>
            </v-col>
          </v-card>
        </v-col>
      </center>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      password: null,
      email: null,
    };
  },
  created() {
    if (localStorage.getItem("Token") != undefined) {
      this.$router.push("/");
    }
  },
  methods: {
    login() {
      axios
        .post("https://winsoc-caller-backend.herokuapp.com/api/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          localStorage.setItem("Token", res.data.data.token);
          localStorage.setItem("ID", res.data.data.id);
          location.reload(true);
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>